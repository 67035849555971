
import React from 'react'
import Chatbot from '../../templates/chatbot'

const ChatbotEsPage = () => {
    return (
        <Chatbot lang="en">
        </Chatbot>
    )
}

export default ChatbotEsPage
